* {
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  --antd-wave-shadow-color: #252525 !important;
}

body {
  background: #fff;
}

::selection {
  color: #fff;
  background: #252525 !important;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.footer {
  padding: 0;
}

.ant-layout-content {
  background: #fff;
}

.title-text {
  margin-bottom: 0px;
  margin-left: 24px;
  margin-right: 24px;
  font-weight: 300;
  font-size: 28px;
}

.subtitle-text {
  text-align: center;
  font-size: 22px;
  font-weight: 300;
}

.required::before {
  content: '●';
  color: #252525;
  font-size: 12px;
  margin-right: 6px;
  display: inline-block;
  position: relative;
  top: -2px;
}

.ant-form-item {
  margin-bottom: 12px !important;
}

.center {
  text-align: center;
}

.clickable {
  cursor: pointer;
}

.empty-header {
  flex: 1;
  line-height: 72px;
  margin-bottom: 0;
  font-size: 30px;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.color-type {
  width: 240px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  padding: 6px 16px;
  border: 1px solid #eee;
  border-radius: 4px;
}

.delete-badge {
  color: #ff4466;
  font-size: 16px;
}

.ant-table-thead
  > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td,
.ant-table-tbody
  > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td,
.ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
  background: #fafafa !important;
}

.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #ddd !important;
}

.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #ddd !important;
}

.line-through {
  text-decoration: line-through;
}

.ant-form-item-label > label::after {
  content: '' !important;
}

.m-t-40 {
  margin-top: 40px;
}

.ant-card-bordered-shadow {
  border: none !important;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 0.0625rem 0px,
    rgba(0, 0, 0, 0.04) 0px 0.5625rem 1.875rem 0px, rgba(0, 0, 0, 0.03) 0px 0.25rem 0.75rem 0px;
  background-color: rgb(255, 255, 255);
  border-radius: 0.5rem;
  transition: transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;
}

.ant-card-bordered-shadow:hover {
  transform: translateY(-0.1875rem);
}
